import { Suspense } from "react";
import { RouteInterface } from "types/generalTypes";


const RequiredAuth = (props: RouteInterface) => {
 
    return (
      <>
        <Suspense >
          <props.element />
        </Suspense>
      </>
    )
};

export default RequiredAuth;
