import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { lazy, useMemo, useState } from "react";
import { RouteInterface } from "types/generalTypes";
import RequiredAuth from "components/authGuard/RequiredAuth";

function App() {
  const publicRoutes = [
    {
      path: "/",
      element: lazy(() => import("./pages/reviews/Ratings")),
      isPublic: true,
    },
  ];
  const [loading] = useState<boolean>(false);
  const [allRoutes] = useState<RouteInterface[]>(publicRoutes);
  const initialRoutes = useMemo(() => {
    if (allRoutes.length > 0) {
      const Routes = [
        ...(allRoutes?.map((route: RouteInterface) => (
          <Route
            index={route?.index ? true : false}
            key={route.path}
            path={route.path}
            element={<RequiredAuth {...route} />}
          ></Route>
        )) || []),
      ];
      return Routes;
    }
    return [];
  }, [allRoutes]);
  return (
    <div className="App">
      <BrowserRouter>
        {loading ? (
          "loading..."
        ) : (
          <>
            <Routes>
              {initialRoutes.length > 0 && initialRoutes.map((route) => route)}
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
